<template>
    <el-dialog title="更换运营" :visible.sync="isShow" width="480px" :before-close="onClose" @opened="onOpened">
        <el-form v-if="isShow" :model="formData" :rules="rules" ref="formData" label-width="70px">
            <el-form-item label="意向" prop="intentTime">
                <el-date-picker
                    v-model="formData.intentTime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    type="datetimerange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width: 100%"
                    :default-time="['00:00:00', '23:59:59']"
                />
            </el-form-item>
            <el-form-item label="运营" prop="staffId">
                <StaffSelect style="width: 100%" @change="(val) => (formData.staffId = val)" />
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="onClose">取 消</el-button>
            <el-button type="primary" @click="onSubmit">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { transferRecord } from '@/js/api/saleApi.js'
import StaffSelect from '@/views/components/StaffSelect'

export default {
    props: ['isShow', 'activeItem', 'projectNo', 'staffList'],
    components: { StaffSelect },
    data() {
        return {
            formData: {
                intentTime: '',
                staffId: '',
            },
            rules: {
                intentTime: [{ required: true, message: '请选择', trigger: 'change' }],
                staffId: [{ required: true, message: '请选择', trigger: 'change' }],
            },
        }
    },
    methods: {
        onOpened() {
            this.formData = {
                intentTime: '',
                staffId: '',
            }
            this.$refs.formData.resetFields()
        },
        onClose() {
            this.formData = null
            this.$emit('onClose')
        },
        onSubmit() {
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    const params = {
                        projectNo: this.projectNo,
                        toStaffId: this.formData.staffId,
                        intentId: this.activeItem.id,
                        userId: this.activeItem.userId,
                        intentTimeStart: this.formData.intentTime[0],
                        intentTimeEnd: this.formData.intentTime[1],
                    }
                    transferRecord(params).then(() => {
                        this.$message.success('编辑成功')
                        this.onClose()
                        this.$emit('onClose', true)
                    })
                }
            })
        },
    },
}
</script>
