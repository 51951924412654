<template>
    <el-drawer :title="title" :visible.sync="isShow" size="640px" :before-close="onClose" @opened="onOpened">
        <el-timeline v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.2)">
            <el-timeline-item
                :color="item.color"
                v-for="(item, index) in tableData"
                :key="`timeline${index}`"
                :timestamp="item.addTime"
                placement="top"
            >
                <el-card class="detail">
                    <div v-if="item.type === 'intention'">
                        <Tag :color="item.tagColor">{{ item.typeName }}</Tag>
                    </div>
                    <div v-else-if="item.type === 'reply'">
                        <StaffName :staff="item.staff" />
                    </div>
                    <div class="content">{{ item.content || '-' }}</div>
                </el-card>
            </el-timeline-item>
        </el-timeline>
    </el-drawer>
</template>
<script>
import { getOppoIntentRecords, getOppoIntentDeals } from '@/js/api/saleApi.js'
import { getStaffList } from '@/js/api/staffApi.js'
import dayjs from 'dayjs'

export default {
    props: ['isShow', 'activeItem', 'projectNo', 'intentTypeMap'],
    data() {
        return {
            title: '',
            loading: false,

            tableData: [],
        }
    },
    methods: {
        onOpened() {
            this.title = '意向详情'
            this.tableData = []
            if (this.activeItem) this.title = `${this.activeItem.userInfo.nickname} - 意向详情`
            this.getOppoIntentData()
        },
        onClose() {
            this.$emit('onClose')
        },

        async getOppoIntentData() {
            this.loading = true
            const res1 = await Promise.all([
                getOppoIntentRecords({ projectNo: this.projectNo, intentId: this.activeItem.id }),
                getOppoIntentDeals({ projectNo: this.projectNo, intentId: this.activeItem.id }),
            ])
            const _list = []
            res1[0].data.forEach((item) => {
                item.addTime = item.intentTime
                if (this.intentTypeMap[item.type]) {
                    item.tagColor = this.intentTypeMap[item.type]['color']
                    item.typeName = this.intentTypeMap[item.type]['name']
                } else {
                    item.tagColor = '#aaaaaa'
                    item.typeName = '未知'
                }
                item.timeStamp = dayjs(item.intentTime).valueOf()
                item.color = '#409EFF'
                item.type = 'intention'
                _list.push(item)
            })

            const _staffIds = []
            res1[1].data.forEach((item) => {
                item.type = 'reply'
                item.addTime = item.dealTime
                item.timeStamp = dayjs(item.dealTime).valueOf()
                _list.push(item)

                if (!_staffIds.includes(item.staffId)) {
                    _staffIds.push(item.staffId)
                }
            })
            _list.sort((a, b) => a.timeStamp - b.timeStamp)

            if (_staffIds.length !== 0) {
                const res2 = await getStaffList({ staffIds: _staffIds })
                const _staffMap = {}
                res2.data.forEach((i) => {
                    _staffMap[i.id] = i
                })

                _list.forEach((item) => {
                    item.staff = _staffMap[item.staffId]
                })
            }
            this.loading = false
            this.tableData = _list
        },
    },
}
</script>
<style lang="less" scoped>
.el-timeline {
    min-height: 300px;
}
.detail {
    margin: 12px 0 0 0;
}
.content {
    padding: 6px 0;
    white-space: pre-line;
    margin-top: 12px;
    line-height: 1.5em;
}
.opt_user {
    margin-left: 12px;
    font-size: 12px;
    color: #666;
}
</style>
