<template>
    <div class="page_container">
        <div class="query_header">
            <div class="query_conditions">
                <div class="condition_item">
                    <span class="condition_label">项目</span>
                    <span class="condition_content">
                        <ProjectSelect @change="onProjectNoChange" />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">意向分类</span>
                    <span class="condition_content">
                        <el-select v-model="intentTypes" clearable multiple>
                            <el-option
                                v-for="item in intentTypeList"
                                :key="item.taskNo"
                                :value="item.taskNo"
                                :label="item.taskName"
                            />
                        </el-select>
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_content">
                        <el-input
                            clearable
                            placeholder="请输入内容"
                            v-model.trim="queryTypeValue"
                            @change="onUserIdChange"
                            @keyup.enter.native="onPaginationChange('page', 1)"
                        >
                            <el-select
                                @change="onQuerySelectChange"
                                class="query_type"
                                v-model="queryType"
                                slot="prepend"
                                placeholder="请选择"
                            >
                                <el-option label="学员ID" value="userId"></el-option>
                                <el-option label="机会ID" value="oppoId"></el-option>
                                <el-option label="学员昵称" value="nickname"></el-option>
                            </el-select>
                        </el-input>
                    </span>
                </div>

                <div class="condition_item">
                    <span class="condition_label">处理状态</span>
                    <span class="condition_content">
                        <el-select v-model="dealFlag" clearable>
                            <el-option :value="true" label="已处理"></el-option>
                            <el-option :value="false" label="未处理"></el-option>
                        </el-select>
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">最近意向时间</span>
                    <span class="condition_content">
                        <el-date-picker
                            v-model="recentIntent"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            type="datetimerange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :default-time="['00:00:00', '23:59:59']"
                        />
                    </span>
                </div>
                <div class="condition_item" v-if="manageGroupList.length !== 0">
                    <span class="condition_label">运营</span>
                    <span class="condition_content">
                        <StaffCascader
                            ref="staffCascader"
                            :manageGroupList="manageGroupList"
                            :clearable="true"
                            @change="(val) => (staffId = val[0])"
                        />
                    </span>
                </div>
            </div>
            <div class="opt_container">
                <el-button @click="onReset">重置</el-button>
                <el-button @click="onPaginationChange('page', 1)" type="primary">查询</el-button>
                <ExportFile
                    class="float_left_btn"
                    host="hunter"
                    path="/oppoIntent/exportRecord"
                    @before-export="onBeforeExport"
                />
            </div>
        </div>
        <el-table :data="tableData" stripe border>
            <el-table-column align="center" type="index" label="序号" width="72" />
            <el-table-column align="center" width="210" label="微信信息">
                <template slot-scope="scope">
                    <UserView v-if="scope.row.userInfo" :key="scope.row.id" :userInfo="scope.row.userInfo" />
                    <span v-else>-</span>
                </template>
            </el-table-column>
            <el-table-column header-align="center" label="最近意向记录">
                <template slot-scope="scope">
                    <div class="record" v-if="scope.row.lastIntentRecord">
                        <div>
                            <span class="label">时间：</span>
                            <span>{{ scope.row.lastIntentRecord.intentTime }}</span>
                        </div>
                        <div>
                            <span class="label">内容：</span>
                            <span>{{ scope.row.lastIntentRecord.content }}</span>
                        </div>
                        <div>
                            <span class="label">类型：</span>
                            <Tag
                                v-if="intentTypeMap && intentTypeMap[scope.row.lastIntentRecord.type]"
                                :color="intentTypeMap[scope.row.lastIntentRecord.type]['color']"
                            >
                                {{ intentTypeMap[scope.row.lastIntentRecord.type]['name'] }}
                            </Tag>
                            <span v-else>-</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column header-align="center" label="最近处理记录">
                <template slot-scope="scope">
                    <div class="record" v-if="scope.row.lastDealRecord">
                        <div>
                            <span class="label">时间：</span>
                            <span>{{ scope.row.lastDealRecord.dealTime }}</span>
                        </div>
                        <div>
                            <span class="label">内容：</span>
                            <span>{{ scope.row.lastDealRecord.content }}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column align="center" label="付费状态" width="100">
                <template slot-scope="scope">
                    <el-tag effect="dark" v-if="scope.row.paidFlag" type="success">已付费</el-tag>
                    <el-tag effect="dark" v-else type="info">未付费</el-tag>
                </template>
            </el-table-column>
            <el-table-column align="center" label="处理状态" width="100">
                <template slot-scope="scope">
                    <el-tag effect="dark" v-if="scope.row.dealFlag" type="success">已处理</el-tag>
                    <el-tag effect="dark" v-else type="info">未处理</el-tag>
                </template>
            </el-table-column>
            <el-table-column align="center" label="运营" width="100">
                <template slot-scope="scope">
                    <StaffName :staff="scope.row.staff" />
                </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="90">
                <template slot-scope="scope">
                    <el-link type="primary" @click="onShowIntentionDetail(scope.row)">意向详情</el-link>
                    <el-link type="info" @click="onShowTransferStaff(scope.row)">更换运营</el-link>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            background
            @size-change="(val) => onPaginationChange('size', val)"
            @current-change="(val) => onPaginationChange('page', val)"
            :current-page.sync="page"
            :page-size="pageSize"
            :page-sizes="[10, 20, 50, 100]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        />

        <IntentionDetail
            :isShow="isShowIntentionDetail"
            :projectNo="projectNo"
            :intentTypeMap="intentTypeMap"
            :activeItem="activeItem"
            @onClose="onCloseIntentionDetail"
        />

        <TransferStaff
            :isShow="isShowTransferStaff"
            :projectNo="projectNo"
            :activeItem="activeItem"
            @onClose="onCloseTransferStaff"
        />
    </div>
</template>
<script>
import { filterQueryEmpty, isEmptyArray, isUserId, generateColorFromString } from '@/js/utils.js'
import { getIntentTypeList, getOppoIntentList } from '@/js/api/saleApi.js'
import { userList } from '@/js/api/userApi.js'
import { getStaffGroupList, getStaffList } from '@/js/api/staffApi.js'
import { mapGetters } from 'vuex'
import ProjectSelect from '@/views/components/ProjectSelect'
import StaffCascader from '@/views/components/StaffCascader'
import UserView from '@/views/components/UserView'
import IntentionDetail from './components/intentionList/IntentionDetail.vue'
import TransferStaff from './components/intentionList/TransferStaff.vue'

let _localStorageKey = 'intentionList_queryType'
export default {
    computed: { ...mapGetters(['userInfo']) },
    components: { ProjectSelect, StaffCascader, UserView, TransferStaff, IntentionDetail },
    data() {
        return {
            intentTypeMap: null,

            projectNo: '',
            queryTypeValue: '',
            queryType: 'userId',
            intentTypes: [],
            intentTypeList: [],
            dealFlag: '',
            recentIntent: [],
            staffId: '',
            manageGroupList: [],

            tableData: [],
            page: 1,
            pageSize: 10,
            total: 0,

            activeItem: null,
            isShowIntentionDetail: false,
            isShowTransferStaff: false,
        }
    },
    created() {
        this.queryType = localStorage.getItem(_localStorageKey) || 'userId'

        this.getIntentTypeListApi()
        this.getStaffGroupData()
    },
    methods: {
        onReset() {
            this.queryTypeValue = ''
            this.intentTypes = []
            this.dealFlag = ''
            this.recentIntent = []
            this.staffId = ''
            if (this.$refs.staffCascader) {
                this.$refs.staffCascader.onClear()
            }

            this.onPaginationChange('page', 1)
        },
        onProjectNoChange(val) {
            this.projectNo = val
            this.onReset()
        },

        onUserIdChange() {
            if (this.queryType === 'userId' && this.queryTypeValue && !isUserId(this.queryTypeValue)) {
                this.queryTypeValue = ''
                this.$message.warning('请输入正确的 学员ID')
            }
        },
        onQuerySelectChange() {
            localStorage.setItem(_localStorageKey, this.queryType)
            this.onUserIdChange()
        },

        onPaginationChange(type, value) {
            if (type === 'size') {
                this.pageSize = value
                this.page = 1
            } else {
                this.page = value
            }
            this.$nextTick(this.getOppoIntentListApi)
        },

        onBeforeExport(cb) {
            cb(this.getParams())
        },

        // 意向详情
        onShowIntentionDetail(item) {
            this.activeItem = item
            this.isShowIntentionDetail = true
        },
        onCloseIntentionDetail() {
            this.activeItem = null
            this.isShowIntentionDetail = false
        },

        // 更换运营
        onShowTransferStaff(item) {
            this.activeItem = item
            this.isShowTransferStaff = true
        },
        onCloseTransferStaff(state) {
            this.activeItem = null
            this.isShowTransferStaff = false
            if (state) this.getOppoIntentListApi()
        },

        getParams() {
            let params = { dealFlag: this.dealFlag, projectNo: this.projectNo, staffId: this.staffId }
            params.intentTypes = !isEmptyArray(this.intentTypes) ? this.intentTypes : null
            if (!isEmptyArray(this.recentIntent)) {
                params.recentIntentStart = this.recentIntent[0]
                params.recentIntentEnd = this.recentIntent[1]
            }
            params[this.queryType] = this.queryTypeValue

            if (this.userInfo.isAdmin) {
                // 管理员
                params.staffId = this.staffId
            } else if (this.manageGroupList.length !== 0) {
                // 主管
                if (this.staffId) {
                    params.groupIds = this.manageGroupList.map((i) => i.id)
                } else {
                    params.staffId = this.staffId
                }
            } else {
                // 员工
                params.staffId = this.userInfo.id
            }

            params = filterQueryEmpty(params)
            return params
        },

        async getOppoIntentListApi() {
            let params = {
                page: this.page,
                size: this.pageSize,
                showPage: true,
                orderBy: '-id',
                ...this.getParams(),
            }
            params = filterQueryEmpty(params)
            const res1 = await getOppoIntentList(params)
            if (res1.data.list.length !== 0) {
                const _userIds = []
                const _staffIds = []
                res1.data.list.forEach((item) => {
                    if (!_userIds.includes(item.userId)) {
                        _userIds.push(item.userId)
                    }
                    if (!_staffIds.includes(item.staffId)) {
                        _staffIds.push(item.staffId)
                    }
                })
                const res2 = await Promise.all([userList({ ids: _userIds }), getStaffList({ staffIds: _staffIds })])
                const _userMap = {}
                res2[0].data.forEach((i) => {
                    _userMap[i.id] = i
                })
                const _staffMap = {}
                res2[1].data.forEach((i) => {
                    _staffMap[i.id] = i
                })

                res1.data.list.forEach((item) => {
                    item.userInfo = _userMap[item.userId]
                    item.staff = _staffMap[item.staffId]
                })
            }
            this.tableData = res1.data.list
            this.page = res1.data.pageNum
            this.total = res1.data.total
        },
        getIntentTypeListApi() {
            getIntentTypeList({}).then((res) => {
                this.intentTypeList = res.data
                const _map = {}
                res.data.forEach((item) => {
                    _map[item.taskNo] = { name: item.taskName, color: generateColorFromString(item.taskNo) }
                })
                this.intentTypeMap = _map
            })
        },
        getStaffGroupData() {
            const params = {}
            if (!this.userInfo.isAdmin) params.manager = this.userInfo.id
            getStaffGroupList(params).then((res) => {
                this.manageGroupList = res.data
            })
        },
    },
}
</script>
<style lang="less" scoped>
.record {
    color: #000;
    font-weight: 700;
    .label {
        font-weight: 400;
        font-size: 12px;
        color: #666;
        margin-right: 8px;
    }
}
</style>
